import Form from "@netivo/base-scripts/javascript/form";

let form = document.querySelector('.js-newsletter-form');

if (form !== null) {
    let action = form.getAttribute('data-action');
    let recaptcha_key = form.getAttribute('data-recaptcha');
    new Form(form, {
        action: action,
        data: el => {
            return new Promise((resolve, reject) => {
                let data = {
                    email: '',
                    captcha: ''
                }
                let form_email = el.querySelector('[name="email"]');

                if (form_email !== null) data.email = form_email.value;

                grecaptcha.ready(function () {
                    grecaptcha.execute(recaptcha_key, {action: 'submit'}).then(function (token) {
                        data.captcha = token;
                        resolve(data);
                    });
                });
            })
        },
        beforeSubmit: (el) => {
            let loader = el.querySelector('[data-element="form-loader"]');
            let l = loader.querySelector('[data-element="loader"]');
            let s = loader.querySelector('[data-element="success"]');
            let e = loader.querySelector('[data-element="error"]');
            l.style.display = 'block';
            s.style.display = 'none';
            e.style.display = 'none'
            loader.style.display = 'flex';
            return true;
        },
        success: (el, response) => {
            let loader = el.querySelector('[data-element="form-loader"]');
            let l = loader.querySelector('[data-element="loader"]');
            let s = loader.querySelector('[data-element="success"]');
            let e = loader.querySelector('[data-element="error"]');
            l.style.display = 'none';
            s.style.display = 'block';
            s.innerHTML = response.message;
            e.style.display = 'none'
            window.setTimeout(() => {

                let form_email = el.querySelector('[name="email"]');

                if (form_email !== null) form_email.value = '';

                loader.style.display = 'none';
            }, 4000);
        },
        error: (el, response) => {
            let loader = el.querySelector('[data-element="form-loader"]');
            let l = loader.querySelector('[data-element="loader"]');
            let s = loader.querySelector('[data-element="success"]');
            let e = loader.querySelector('[data-element="error"]');
            l.style.display = 'none';
            s.style.display = 'none';
            e.style.display = 'block';
            e.innerHTML = response.message;
            window.setTimeout(() => {
                loader.style.display = 'none';
            }, 4000);
        }
    })
}